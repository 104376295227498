// This file is auto generated do not modify directly

export const spacing = {
  "0": "0",
  "1": "15px",
  "2": "30px",
  "3": "60px",
  "4": "90px",
  "5": "120px",
  "6": "150px",
  "7": "180px",
} as const

export const container = {center: true} as const

export const fontSize = {
  note: "13px",
  body: "15px",
  "subheading-2": "19px",
  subheading: "26px",
  heading: "34px",
  title: "44px",
  "heading-lg": "20px",
  "heading-2xl": "30px",
  "heading-3xl": "40px",
} as const

export const borderRadius = {
  none: 0,
  DEFAULT: "5px",
  lg: "6px",
  xl: "8px",
} as const

export const fontFamily = {
  sans: ["Nunito Sans", "ui-sans-serif", "system-ui", "sans-serif"],
} as const

export const colors = {
  white: "#FFFFFF",
  spacemist: "#1D2024",
  cosmosgrey: "#30343A",
  steel: "#555D67",
  smoke: "#879196",
  ash: "#D4D6DE",
  moonstone: "#F9F9FA",
  celestial: "#22594F",
  nova: "#06AB8D",
  mist: "#3FE3BC",
  stardust: "#00FFC2",
  haze: "#F2FBF8",
  solarflare: "#F1381F",
} as const

export const animation = {
  rotate: "rotate 2s linear infinite",
  prixclipfix: "prixClipFix 2s linear infinite",
} as const

export const keyframes = {
  rotate: {"100%": {transform: "rotate(360deg)"}},
  prixClipFix: {
    "0%": {"clip-path": "polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)"},
    "25%": {
      "clip-path": "polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)",
    },
    "50%": {
      "clip-path":
        "polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)",
    },
    "75%": {
      "clip-path": "polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)",
    },
    "100%": {
      "clip-path": "polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)",
      "border-color": "#3FE3BC",
    },
  },
} as const
